import React, { createContext, useState } from "react";

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <GlobalStateContext.Provider value={{ errorMessage, setErrorMessage }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
