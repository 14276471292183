import axios from "axios";
import Announcement from "../components/Announcement";
import { useEffect, useState, useContext } from "react";
import { GlobalStateContext } from "../GlobalStateContext";

const Landing = () => {
  const [announcements, setAnnouncements] = useState([]);
  const { errorMessage, setErrorMessage } = useContext(GlobalStateContext);

  useEffect(() => {
    axios.get("https://halopl.us/api/announcements").then((re) => {
      setAnnouncements(re.data);
    });
  }, []);

  const handleCloseModal = () => {
    setErrorMessage("");
  };

  return (
    <div className="px-[5%] 2xl:px-[20%] py-[25px]">
      <h1 className="font-bold text-[30px]">Welcome to Halo+</h1>
      <p>
        Hey! Welcome to the Halo+ website, I am still working on these pages so
        check back later!
        <br />
        There's not much to view here aside from legal stuff.
      </p>
      <hr className="mt-[10px] mb-[20px]"></hr>
      <h1 className="font-bold text-[30px]">Announcements</h1>
      {announcements.map((announcement) => (
        <Announcement
          key={announcement.id}
          timePosted={announcement.time_posted}
          title={announcement.title}
          description={announcement.description}
        />
      ))}
      {errorMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Error</h2>
            <p>{errorMessage}</p>
            <button
              onClick={handleCloseModal}
              className="mt-4 p-2 bg-red-500 text-white font-bold rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Landing;
