// src/components/Login.js
import { useState } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import Cookies from "js-cookie";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    setError("");
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const token = userCredential.user.uid;

      // Save the session token in a cookie
      Cookies.set("auth_session", token, { expires: 7 }); // Expires in 7 days

      window.location.href = "https://halo.gcu.edu";
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setError("Invalid email address.");
      } else if (error.code === "auth/wrong-password") {
        setError("Incorrect password.");
      } else if (error.code === "auth/invalid-credential") {
        setError("Invalid credentials. Please try again.");
      } else if (error.code === "auth/user-not-found") {
        setError("No user found with this email.");
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };

  return (
    <div className="flex items-center justify-center h-[90vh]">
      <div className="w-[500px] h-[500px] bg-white shadow-lg text-center p-10 flex flex-col justify-center items-center">
        <h1 className="font-bold text-4xl mb-1">
          Login <span className="text-[25px] text-[#787878]">to Halo+</span>
        </h1>
        <p className="text-[#5f5f5f] mb-5">
          <span className="font-bold">Note:</span> Remember that signing in the
          web version of Halo+ allows you to sync your cookies. To access your
          Halo information, you must install the app..
        </p>
        <input
          value={email}
          type="email"
          onChange={(event) => setEmail(event.target.value)}
          className="w-[100%] text-[#606060] p-5 bg-[#CACACA] rounded-3xl focus:outline-none font-bold text-center"
          placeholder="GCU Email Address"
        />
        <input
          value={password}
          placeholder="Password"
          onChange={(event) => setPassword(event.target.value)}
          className="mt-3 w-[100%] text-[#606060] p-5 bg-[#CACACA] rounded-3xl focus:outline-none font-bold text-center"
          type="password"
        />
        <button
          onClick={handleLogin}
          className="w-[100%] p-5 bg-[#ff48f6] rounded-3xl hover:cursor-pointer font-bold mt-3 text-white"
        >
          Login to Halo+
        </button>
        {error && <p className="mt-5 text-red-600 font-semibold">{error}</p>}
        <p className="mt-5 text-[#616161] font-semibold">
          New to Halo+?{" "}
          <a href="/sign-up" className="text-[#b242b0] underline">
            Sign Up!
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
