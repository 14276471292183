const SignUp = () => {
  return (
    <div className="flex items-center justify-center h-[90vh]">
      <div className="w-[500px] h-[500px] bg-white shadow-lg text-center p-10 flex flex-col justify-center items-center">
        <h1 className="font-bold text-4xl mb-5">
          Signing Up{" "}
          <span className="text-[25px] text-[#787878]">to Halo+</span>
        </h1>
        <p className="text-left">
          Looks like you're new to Halo+!
          <br />
          <br />
          Halo+ is a mobilized version of the Halo portal website, meant for GCU
          students to easily access without the use of constantly logging into
          the site. It also allows students to recieve up-to-date notifications
          about grades, announcements, and messages.
          <br />
          <br />
          Unfortunately, to sign up, you will need to install the mobile version
          of Halo+. You can find more information about how to download the app
          at our{" "}
          <a
            className="font-bold text-xl underline text-[#305583]"
            href="https://discord.gg/vCCFHH4kuZ"
          >
            Discord
          </a>
          !
          <p className="mt-5 text-[#616161] font-semibold text-center">
            Already Signed up?{" "}
            <a href="/login" className="text-[#b242b0] underline">
              Login to Halo+
            </a>
          </p>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
