const TermsOfService = () => {
    return <div className="px-[5%] 2xl:px-[31%] py-[25px]">
        <h1 className="font-bold text-[30px]">Terms of Service</h1>
        <h1 className="font-bold text-[20px]">Last Updated: Wednesday 23rd, 2024 at 4:25 PM</h1>
        <hr className="mt-[10px] mb-[20px]"></hr>
        <div>
            <h2 className="text-[25px] mt-[20px] mb-[8px]">1. Acceptance of Terms</h2>
            <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an
                entity ("you") and John Aquino ("Individual", "Company", "we", "us", or "our"), concerning your access to and use of Halo+ as
                well as any other media form, media channel, website, browser extension, mobile
                website or mobile application related, linked, or otherwise connected thereto (collectively, the "Service"). You
                agree that by accessing the Service, you have read, understood, and agreed to be bound by all of these Terms of
                Use.<br /><br />
                <b>IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
                    SERVICE AND YOU MUST DISCONTINUE USE IMMEDIATELY.</b>
                <br />
                <br />
                The Service is intended for users who are at least 18 years of age. All users who are minors in the jurisdiction
                in which they reside (generally under the age of 18) are prohibited from using this service.
            </p>

            <h2 className="text-[25px] mt-[20px] mb-[8px]">2. Prohibited Actions</h2>
            <ol>
                <li><strong>Decompiling or Reverse Engineering:</strong> You may not attempt to reverse engineer, decompile, or
                    otherwise attempt to derive the source code of Halo+.</li>
                <li><strong>Abusing Bugs:</strong> If you discover any bug or vulnerability in our service, you must report it
                    to us immediately and not exploit, disclose, or use it in any harmful way.</li>
                <li><strong>Harming the Project:</strong> You may not take actions that could harm, disrupt, or manipulate the
                    integrity or performance of Halo+.</li>
            </ol>

            <h2 className="text-[25px] mt-[20px] mb-[8px]">3. Changes to the Terms</h2>
            <p>We reserve the right to modify or replace these Terms of Service at any time. It is your responsibility to check
                this page periodically for changes.</p>

            <h2 className="text-[25px] mt-[20px] mb-[8px]">4. Famly Educational Rights and Privacy Act (FERPA)</h2>
            <p>Under the United States Family Educational Rights and Privacy Act (FERPA), users of the Service are granted
                certain rights regarding their "education records" and "personally identifiable information" (as defined in <a className="text-[#a945b2] underline" href="https://studentprivacy.ed.gov/ferpa#0.1_se34.1.99_13">34
                    CFR§ 99.3</a>).

                <br /><br />The Service knowingly processes and stores
                such education records, in accordance with its primary functionality.
                <br /><br />
                <b>BY INSTALLING THE SERVICE AND CONTINUING TO USE IT, YOU CONFIRM YOUR CONSENT TO AUTHORIZE THE SERVICE TO
                    PROGRAMMATICALLY AND CONTINUALLY VIEW, COLLECT, HANDLE, PROCESS, STORE, AND OTHERWISE INTERACT WITH YOUR
                    EDUCATION RECORDS AND PERSONALLY IDENTIFIABLE INFORMATION.</b>
                <br /><br />
                The Service will use education records only for the purpose of fulfilling its duties and providing utility under
                this agreement, and for improving its duties and utility under this agreement.
                <br /><br />
                The Service is prohibited from data mining education records for any purposes other than those agreed to by the
                parties. Data mining or scanning of user content for the purpose of advertising or marketing to users is
                prohibited.
                <br /><br />
                The Service cannot share education records with any additional parties without your prior written consent,
                except as required by law.
                <br /><br />
                The Service will store and process education records in accordance with industry best practices. This includes
                appropriate administrative, physical, and technical safeguards to secure education records from unauthorized
                access, disclosure, and use. The Service will conduct periodic risk assessments and remediate any identified
                security vulnerabilities in a timely manner.
                <br /><br />
                The Service will never view, collect, handle, process, or otherwise interact with any education records that are
                considered protected by a user's rights under FERPA, when that user has explicitly denied the Service the
                ability to perform the aforementioned actions with their protected education records. To explicitly and formally
                deny the Service access to your education records, please submit a digital request at <a className="text-[#a945b2] underline" href="/discord">our
                    discord</a>.
            </p>
            <h2 className="text-[25px] mt-[20px] mb-[8px]">5. Contact Us</h2>
            <p>If you have questions about these terms, please contact us at <a className="text-[#a945b2] underline" href="/discord">our discord</a>.</p>
        </div>
    </div>
};

export default TermsOfService;