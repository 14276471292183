// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCqRVijZFnPg7rulo-dpARsl339MHrui90",
  authDomain: "halopl.us",
  databaseURL: "https://haloplus-a9fe8-default-rtdb.firebaseio.com",
  projectId: "haloplus-a9fe8",
  storageBucket: "haloplus-a9fe8.appspot.com",
  messagingSenderId: "551897292912",
  appId: "1:551897292912:web:4a13e8de8fa05a0ea294b5",
  measurementId: "G-VSPMRG1JSK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
