import { FaEnvelope, FaDiscord } from "react-icons/fa";

export default function Support() {
  return (
    <div className="flex flex-col justify-center items-center h-full p-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Get Support</h1>

      <p className="text-lg text-gray-600 mb-8 text-center max-w-lg">
        Need help or have questions? Join our Discord community for real-time
        support, or reach out via email if you prefer.
      </p>

      {/* Contact buttons */}
      <div className="flex flex-col items-center space-y-4">
        <a
          href="https://discord.gg/zyEph3zQxT"
          className="flex items-center bg-purple-500 text-white py-3 px-6 rounded-full shadow-md hover:bg-purple-600 transition duration-300 ease-in-out"
        >
          <FaDiscord className="mr-2" /> Join our Discord for Support
        </a>
        <p className="text-gray-500">or</p>
        <a
          href="mailto:william@piehsoft.com"
          className="flex items-center bg-blue-500 text-white py-2 px-4 rounded-full shadow-md hover:bg-blue-600 transition duration-300 ease-in-out"
        >
          <FaEnvelope className="mr-2" /> Email us
        </a>
      </div>

      {/* Additional footer links */}
      <div className="mt-8">
        <a
          href="/privacy-policy"
          className="text-blue-500 hover:underline text-sm"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
}
