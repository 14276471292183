import loginPreview from "../images/login_preview.png";
import downloadExtension from "../images/download_extension.png";
import loginToHaloPlus from "../images/login_to_haloplus.png";
import syncPreview from "../images/sync_preview.png";

export default function Setup() {
  return (
    <div className="m-14">
      <h1 className="text-5xl font-bold text-center">Setting up Halo+</h1>
      <div className="flex flex-col md:flex-row items-start justify-center mt-3 space-x-5">
        <div className="md:w-1/2 max-w-xl mt-5">
          <h1 className="text-2xl text-center">Installing with iOS</h1>
          <p className="mt-5">
            <strong>1. Install Halo+ in the App Store</strong>
            <br />
            Open the App Store on your iOS device and search for "Halo+". Tap
            the download button to install the application.
            <br />
            <i>
              <a
                href="https://appdistribution.firebase.dev/i/ce5ab13e4b5df5bf"
                className="underline"
              >
                While we wait for our app to release in the App Store, please
                install our application here. (Please read step 1.1 to 1.4)
              </a>
            </i>
            <br />
            <strong className="ml-5">1.1. Allowing Developer Apps</strong>
            <br />
            <p className="ml-5">
              For the next step, you will have to click on the App Distribution
              link, and install a profile which will require you to go to your
              device certificate settings, and approve and install the profile
              for App Distribution. The next step, after having done this, is to
              head to your settings, and click on Privacy & Security. You will
              then scroll down to the bottom, where you see an option called
              Developer Mode. Click on it and enable developer mode. It will
              require you to restart your device.
            </p>
            <strong className="ml-5">1.2. Waiting for Approval</strong>
            <br />
            <p className="ml-5">
              Once you have done this, you can return back to the App
              Distribution link (should've created a shortcut on your screen, if
              not, simply search for it and it will send you to the screen). At
              this point, you'll need to click on Register Device. If that does
              appear, and it says you are waiting for the device to be approved.
              That will send me your device info, and we can add it to the list
              of permitted devices to run the application.
            </p>
            <strong className="ml-5">1.3. Using the app</strong>
            <br />
            <p className="ml-5">
              Once we have pushed out the newest build, you'll be notified that
              you have access to the latest version, which includes your ability
              to download it. Go ahead and download it.
              <br />
              <br />
              Go ahead and follow step <strong>2.</strong> until you reach step{" "}
              <strong>7.</strong>
              <br />
            </p>
            <br />
            <strong>2. Registering an account</strong>
            <br />
            Once inside the app, click on the register button, and fill in your
            information according to the register requirements
            <br />
            <i>You are required to register with your verified GCU email.</i>
            <br />
            <br />
            <strong>3. Verifying your email</strong>
            <br />
            You should receive a link that sends you to verify your email
            address. Go ahead and use your login to verify the address.
            <br />
            <br />
            <strong>4. Download our Chrome Extension</strong>
            <br />
            Head onto the Chrome Web Store and search for{" "}
            <a
              href="https://chromewebstore.google.com/detail/halo+-sync/gmbglfgocfeknkbkalalejggdggemjhb"
              className="underline"
            >
              "Halo+ Sync"
            </a>
            . Click on the "Add to Chrome" button to install the extension.
            <br />
            <img
              src={downloadExtension}
              alt="Download Chrome extension for Halo+"
            ></img>
            <br />
            <strong>5. Head to Halo Portal</strong>
            <br />
            In order for us to sync your data, you need to head to the Halo
            Portal and login with your GCU email. You can find the portal at{" "}
            <a href="https://halo.gcu.edu" className="underline">
              https://halo.gcu.edu/
            </a>
            .<br />
            <img
              alt="A preview of the Halo Portal login screen."
              src={loginPreview}
            ></img>
            <br />
            <strong>6. Login into Halo+</strong>
            <br />
            Once you have installed the extension, you must log in with your GCU
            email and password before syncing your data.
            <br />
            <img src={loginToHaloPlus} alt="Login screen for Halo+"></img>
            <br />
            <strong>7. Sync your data</strong>
            <br />
            Once you have logged in, click on the "Sync Data" button to sync
            your data.
            <br />
            <img
              src={syncPreview}
              alt="Preview of data sync screen on Halo+"
            ></img>
            <strong>8. Open the app</strong>
            <br />
            You are now ready to use Halo+ on your iOS device. Just login with
            your GCU email and password and you're done!
            <br />
          </p>
        </div>
        <div className="md:w-1/2 max-w-xl mt-5">
          <h1 className="text-xl text-center">Installing with Android</h1>
          <p className="mt-5">
            <strong>1. Install Halo+ with Firebase App Distribution</strong>
            <br />
            <i>
              <a
                href="https://appdistribution.firebase.dev/i/d62122517372a2f9"
                className="underline"
              >
                Download our application here.
              </a>
            </i>
            <br />
            <br />
            <strong>2. Registering an account</strong>
            <br />
            Once inside the app, click on the register button, and fill in your
            information according to the register requirements
            <br />
            <i>You are required to register with your verified GCU email.</i>
            <br />
            <br />
            <strong>3. Verifying your email</strong>
            <br />
            You should receive a link that sends you to verify your email
            address. Go ahead and use your login to verify the address.
            <br />
            <br />
            <strong>4. Download our Chrome Extension</strong>
            <br />
            Head onto the Chrome Web Store and search for{" "}
            <a
              href="https://chromewebstore.google.com/detail/halo+-sync/gmbglfgocfeknkbkalalejggdggemjhb"
              className="underline"
            >
              "Halo+ Sync"
            </a>
            . Click on the "Add to Chrome" button to install the extension.
            <br />
            <img
              src={downloadExtension}
              alt="Download Chrome extension for Halo+"
            ></img>
            <br />
            <strong>5. Head to Halo Portal</strong>
            <br />
            In order for us to sync your data, you need to head to the Halo
            Portal and login with your GCU email. You can find the portal at{" "}
            <a href="https://halo.gcu.edu" className="underline">
              https://halo.gcu.edu/
            </a>
            .<br />
            <img
              alt="A preview of the Halo Portal login screen."
              src={loginPreview}
            ></img>
            <br />
            <strong>6. Login into Halo+</strong>
            <br />
            Once you have installed the extension, you must log in with your GCU
            email and password before syncing your data.
            <br />
            <img src={loginToHaloPlus} alt="Login screen for Halo+"></img>
            <br />
            <strong>7. Sync your data</strong>
            <br />
            Once you have logged in, click on the "Sync Data" button to sync
            your data.
            <br />
            <img
              src={syncPreview}
              alt="Preview of data sync screen on Halo+"
            ></img>
            <strong>8. Open the app</strong>
            <br />
            You are now ready to use Halo+ on your Android device. Just login
            with your GCU email and password and you're done!
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
