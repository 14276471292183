import { useState } from "react";
import HaloNavBanner from "../images/halo_plus_banner.png";
import { AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full">
      <div className="bg-[#522398] text-[#ffff] h-[5rem] p-[20px] flex flex-row items-center justify-between lg:justify-start">
        {/* Logo */}
        <img
          src={HaloNavBanner}
          className="h-[3rem] lg:mx-[1rem] w-[9rem]"
          alt="halo plus banner"
        />

        {/* Hamburger Menu for Mobile */}
        <div className="lg:hidden">
          <AiOutlineMenu
            className="text-3xl cursor-pointer"
            onClick={toggleMenu}
          />
        </div>

        {/* Desktop Menu Links */}
        <div className="hidden lg:flex flex-row items-center">
          <a
            href="/"
            className="text-center font-semibold uppercase hover:text-[#b7b7b7] hover:bg-[#b7b7b7] hover:bg-opacity-[20%] bg-opacity-[20%] rounded-[5px] bg-[#ffffff] ml-[1rem] h-[2.5rem] w-[12rem] mr-[0.2rem] p-[10px] px-[20px]"
          >
            What is Halo+?
          </a>
          <a
            href="/terms"
            className="text-center font-semibold uppercase hover:text-[#b7b7b7] hover:bg-[#b7b7b7] hover:bg-opacity-[20%] bg-opacity-[20%] rounded-[5px] bg-[#ffffff] ml-[1rem] h-[2.5rem] w-[12rem] mr-[0.2rem] p-[10px] px-[20px]"
          >
            Terms of Service
          </a>
          <a
            href="/privacy"
            className="text-center font-semibold uppercase hover:text-[#b7b7b7] hover:bg-[#b7b7b7] hover:bg-opacity-[20%] bg-opacity-[20%] rounded-[5px] bg-[#ffffff] ml-[1rem] h-[2.5rem] w-[12rem] mr-[0.2rem] p-[10px] px-[20px]"
          >
            Privacy Policy
          </a>
          <a
            href="/support"
            className="text-center font-semibold uppercase hover:text-[#b7b7b7] hover:bg-[#b7b7b7] hover:bg-opacity-[20%] bg-opacity-[20%] rounded-[5px] bg-[#ffffff] ml-[1rem] h-[2.5rem] w-[12rem] mr-[0.2rem] p-[10px] px-[20px]"
          >
            Support
          </a>
          <a
            href="/setup"
            className="text-center font-semibold uppercase hover:text-[#b7b7b7] hover:bg-[#b7b7b7] hover:bg-opacity-[20%] bg-opacity-[20%] rounded-[5px] bg-[#ffffff] ml-[1rem] h-[2.5rem] w-[12rem] mr-[0.2rem] p-[10px] px-[20px]"
          >
            Setup
          </a>
        </div>

        {/* Mobile Menu Links */}
        {isOpen && (
          <div className="lg:hidden flex flex-col items-center bg-[#522398] text-[#ffff] absolute top-[5rem] left-0 right-0 w-full z-10">
            <a
              href="/"
              className="block text-center font-semibold uppercase hover:text-[#b7b7b7] hover:bg-[#b7b7b7] hover:bg-opacity-[20%] bg-opacity-[20%] rounded-[5px] bg-[#ffffff] mb-2 py-[10px] px-[20px] w-[90%]"
              onClick={toggleMenu}
            >
              What is Halo+?
            </a>
            <a
              href="/terms"
              className="block text-center font-semibold uppercase hover:text-[#b7b7b7] hover:bg-[#b7b7b7] hover:bg-opacity-[20%] bg-opacity-[20%] rounded-[5px] bg-[#ffffff] mb-2 py-[10px] px-[20px] w-[90%]"
              onClick={toggleMenu}
            >
              Terms of Service
            </a>
            <a
              href="/privacy"
              className="block text-center font-semibold uppercase hover:text-[#b7b7b7] hover:bg-[#b7b7b7] hover:bg-opacity-[20%] bg-opacity-[20%] rounded-[5px] bg-[#ffffff] mb-2 py-[10px] px-[20px] w-[90%]"
              onClick={toggleMenu}
            >
              Privacy Policy
            </a>
            <a
              href="/support"
              className="block text-center font-semibold uppercase hover:text-[#b7b7b7] hover:bg-[#b7b7b7] hover:bg-opacity-[20%] bg-opacity-[20%] rounded-[5px] bg-[#ffffff] mb-2 py-[10px] px-[20px] w-[90%]"
              onClick={toggleMenu}
            >
              Support
            </a>
            <a
              href="/setup"
              className="block text-center font-semibold uppercase hover:text-[#b7b7b7] hover:bg-[#b7b7b7] hover:bg-opacity-[20%] bg-opacity-[20%] rounded-[5px] bg-[#ffffff] mb-2 py-[10px] px-[20px] w-[90%]"
              onClick={toggleMenu}
            >
              Setup
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
